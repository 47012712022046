import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate pro navigaci
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import "./About.css";

function About() {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const navigate = useNavigate(); // Inicializace navigace

  const carouselImages = [
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000115-60a2c60a2e/IMG_7782.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000117-070e7070e9/IMG_7783.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000119-6f85a6f85d/IMG_7784.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000125-48b3548b37/IMG_E7786.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000123-ceec3ceec4/IMG_E7785.webp?ph=8f10205334",
  ];

  const galleryImages = [
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000019-3cbef3cbf2/450/359734099_6340060536108981_770880386399261963_n-8.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000095-6898468986/700/IMG_5209.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000023-9bb8c9bb8e/450/Screenshot_2.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000097-2d8ec2d8ef/700/F79827ED-64D1-42A1-8DB1-6B7355B20680.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000099-d3039d303b/700/IMG_5590.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000101-6817e68181/700/83438D41-FE42-4B0B-88D2-1902F8EDB6B8.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000103-d8606d8607/700/F9E728FE-DCE9-4FB9-A3D2-8E82628CCBD0.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000137-267e0267e1/700/9C27467C-766C-4133-9C9C-A14E24BFB8E4.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000149-2619626199/450/IMG_0535.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000151-8fa7b8fa7d/450/IMG_9931.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000153-bba7bbba7e/450/IMG_9932.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000001-47eb647eb9/450/352012211_241701755144456_2916754618011983910_n.webp?ph=8f10205334",
  ];

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className="about-page">
      <h1 className="about-title">Filip Sopek</h1>
      <div className="about-content">
        {/* Section 1 */}
        <div className="about-section">
          <img
            src="https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000077-50bb150bb3/7175A805-1AAE-42A8-BCD7-32A111443B3F-3.webp?ph=8f10205334"
            alt="Filip Sopek"
            className="about-image"
          />
          <p className="about-text">
            <strong>Proč by sis měl vybrat mě?</strong>
            <br />
            Jsem mladý jedinec, který rád pomáhá lidem, celou mou cestou jsem
            si prošel sám, tudíž tě ty věci naučím tak, abys jim rozuměl.
            Naučím tě jíst, cvičit a mít disciplínu.
            <br />
            <br />
            Nevyužívám metody cvičení, jako 97% trenérů, využívám efektivnější
            metody pro nabírání svalů a síly zároveň. Cvičení opravdu NENÍ
            složité, jak někteří rádi zobrazují, ale ve skutečnosti je z velké
            části kontraproduktivní. Využívám metody s nízkým objemem práce a
            vysokou intenzitou.
          </p>
        </div>

        {/* Section 2 */}
        <div className="about-section">
          <img
            src="https://i.ibb.co/q0MK86j/EC98-C37-A-F695-4795-80-F5-36-C3-EE95-CB95.jpg"
            alt="Filip Sopek Training"
            className="about-image"
          />
          <p className="about-text">
            <strong>Chceš přehledný tréninkový plán v aplikaci?</strong>
            <br />
            Tak to ti přesně mohu nabídnout. Využívám aplikace TrueCoach, která
            je světově známá mezi trenéry. Aplikaci můžeš jednoduše stáhnout na
            mobil a budeš v ní mít zobrazený tréninkový plán, cvik po cviku,
            počet sérií a opakování a video s technikou.
          </p>
        </div>
      </div>

      {/* Carousel */}
      <div className="carousel">
        <Slider {...carouselSettings}>
          {carouselImages.map((src, index) => (
            <div key={index}>
              <img
                src={src}
                alt={`Carousel Image ${index + 1}`}
                className="carousel-image"
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* Gallery */}
      <section className="gallery">
        <h2 className="gallery-title">Galerie</h2>
        <div className="gallery-grid">
          {galleryImages.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Gallery image ${index + 1}`}
              className="gallery-image"
              onClick={() => {
                setPhotoIndex(index);
                setIsOpen(true);
              }}
            />
          ))}
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={galleryImages[photoIndex]}
            nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
            prevSrc={
              galleryImages[
                (photoIndex + galleryImages.length - 1) % galleryImages.length
              ]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + galleryImages.length - 1) % galleryImages.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % galleryImages.length)
            }
          />
        )}
      </section>

      {/* Call to Action */}
      <section className="call-to-action">
        <h2>Chceš začít spolupracovat?</h2>
        <button
          className="cta-button"
          onClick={() => navigate("/contact")} // Přesměrování na Contact page
        >
          Vyplň formulář
        </button>
      </section>

      {/* Pricing */}
      <section className="pricing">
        <h2 className="pricing-title">Ceník</h2>
        <div className="pricing-table">
          <div className="pricing-row">
            <span>Tréninkový plán</span>
            <span className="pricing-amount">1000Kč</span>
          </div>
          <div className="pricing-row">
            <span>Jídelníček</span>
            <span className="pricing-amount">800Kč</span>
          </div>
          <div className="pricing-row">
            <span>Kompletní coaching - měsíc</span>
            <span className="pricing-amount">1600Kč</span>
          </div>
          <div className="pricing-row">
            <span>Kompletní coaching - 3 měsíce</span>
            <span className="pricing-amount">3200Kč</span>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <p>© 2024 filipsopek.cz. Všechna práva vyhrazena.</p>
          <div className="footer-links">
            <a href="/about">O mně</a>
            <a href="/contact">Kontakt</a>
            <a href="/privacy">Zásady ochrany osobních údajů</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default About;
