import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Clients from "./Clients";
import Privacy from "./PrivacyPolicy";

function App() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="logo-and-name">
            <img
              src="https://i.ibb.co/mtYDdcn/logo.png" // Nahraďte logem
              alt="Logo"
              className="logo"
            />
            <div className="name">
              <a href="/" className="name">Filip Sopek</a>
            </div>
          </div>
          <button className="nav-toggle" onClick={toggleNav}>
            ☰
          </button>
          <nav className={`nav-menu ${isNavOpen ? "open" : ""}`}>
            <Link to="/" onClick={toggleNav}>Úvod</Link>
            <Link to="/about" onClick={toggleNav}>O mně</Link>
            <Link to="/contact" onClick={toggleNav}>Kontakt</Link>
            <Link to="/clients" onClick={toggleNav}>Klienti</Link>
          </nav>
        </header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
