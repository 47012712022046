import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./Clients.css";

function Clients() {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const galleryImages = [
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000166-27dfa27dfc/700/442136999_364141559986886_1857853432907114988_n.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000163-cb0cecb0d0/700/442158187_1524917658448361_8290572739101320450_n.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000164-05e2905e38/442169320_779074087666524_130530973166628663_n.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000168-2474f24752/700/442199868_346888391347872_92510338349562702_n.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000165-190671906a/700/442214203_1168766761219806_776002158150705319_n.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000169-e01d7e01da/700/442243540_2795698110592781_3923467794455730265_n.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000173-03a0303a06/700/442297156_1189829375545817_2048986918476058065_n.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000171-a8908a8909/700/442243825_969932417662840_5256381849644135688_n.webp?ph=8f10205334",
  ];

  return (
    <div className="clients-page">
      <h1 className="clients-title">Klienti</h1>
      <h2 className="clients-subtitle">VÝSLEDKY KLIENTŮ</h2>

      {/* Grid of Images */}
      <div className="clients-grid">
        {galleryImages.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Klient ${index + 1}`}
            className="client-image"
            onClick={() => {
              setPhotoIndex(index);
              setIsOpen(true);
            }}
          />
        ))}
      </div>

      {/* Lightbox */}
      {isOpen && (
        <Lightbox
          mainSrc={galleryImages[photoIndex]}
          nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
          prevSrc={
            galleryImages[
              (photoIndex + galleryImages.length - 1) % galleryImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + galleryImages.length - 1) % galleryImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % galleryImages.length)
          }
        />
      )}

      {/* YouTube Section */}
      <h2 className="youtube-title">PŘÍPRAVY ZÁVODNÍKŮ</h2>
      <div className="youtube-video">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/DU9QA6GiVHE?si=2MJwBJBcu8NxcP0p"
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <p>© 2024 filipsopek.cz. Všechna práva vyhrazena.</p>
          <div className="footer-links">
            <a href="/about">O mně</a>
            <a href="/contact">Kontakt</a>
            <a href="/privacy">Zásady ochrany osobních údajů</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Clients;
