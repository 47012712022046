import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1 className="privacy-title">Zásady ochrany osobních údajů</h1>

      <p className="intro">
        Vaše soukromí je pro nás velmi důležité. Tento dokument popisuje, jaké
        osobní údaje shromažďujeme, jak je používáme a jak chráníme vaše práva
        v souladu s platnými právními předpisy, včetně nařízení Evropského parlamentu a Rady
        (EU) 2016/679 (obecné nařízení o ochraně osobních údajů, známé jako GDPR).
      </p>

      <section className="section">
        <h2>1. Shromažďované údaje</h2>
        <p>
          Při používání našich služeb můžeme shromažďovat následující typy osobních údajů:
        </p>
        <ul>
          <li>
            <strong>Identifikační údaje:</strong> Jméno, příjmení, e-mailová adresa,
            telefonní číslo.
          </li>
          <li>
            <strong>Údaje o používání:</strong> Informace o tom, jak používáte naše webové
            stránky, včetně IP adresy, typu prohlížeče, času přístupu a odkazujících
            stránek.
          </li>
          <li>
            <strong>Údaje poskytnuté dobrovolně:</strong> Například informace z kontaktních
            formulářů nebo e-mailové komunikace.
          </li>
        </ul>
      </section>

      <section className="section">
        <h2>2. Účely zpracování</h2>
        <p>
          Vaše osobní údaje zpracováváme pouze v rozsahu nezbytném pro následující účely:
        </p>
        <ul>
          <li>Poskytování našich služeb a komunikace s vámi.</li>
          <li>
            Analýza a zlepšování našich produktů a služeb, včetně personalizace
            obsahu a reklamy.
          </li>
          <li>
            Dodržování zákonných povinností, včetně účetních, daňových nebo
            právních požadavků.
          </li>
          <li>
            Ochrana našich práv a oprávněných zájmů, včetně prevence podvodů
            a bezpečnosti systému.
          </li>
        </ul>
      </section>

      <section className="section">
        <h2>3. Právní základy zpracování</h2>
        <p>
          Vaše osobní údaje zpracováváme na základě jednoho nebo více z
          následujících právních základů:
        </p>
        <ul>
          <li>
            <strong>Souhlas:</strong> Pokud jste nám poskytli výslovný souhlas se
            zpracováním vašich údajů pro konkrétní účel.
          </li>
          <li>
            <strong>Plnění smlouvy:</strong> Pokud je zpracování nezbytné pro plnění
            smlouvy, kterou jste s námi uzavřeli.
          </li>
          <li>
            <strong>Právní povinnost:</strong> Pokud je zpracování nezbytné pro splnění
            našich zákonných povinností.
          </li>
          <li>
            <strong>Oprávněné zájmy:</strong> Pokud je zpracování nezbytné pro účely našich
            oprávněných zájmů, za předpokladu, že nepřeváží vaše práva.
          </li>
        </ul>
      </section>

      <section className="section">
        <h2>4. Doba uchovávání údajů</h2>
        <p>
          Vaše osobní údaje uchováváme pouze po dobu nezbytnou pro splnění účelů,
          pro které byly shromážděny, nebo po dobu vyžadovanou příslušnými zákony.
          Po uplynutí této doby budou vaše údaje bezpečně smazány.
        </p>
      </section>

      <section className="section">
        <h2>5. Vaše práva</h2>
        <p>
          V souladu s GDPR máte následující práva týkající se vašich osobních údajů:
        </p>
        <ul>
          <li>
            <strong>Právo na přístup:</strong> Můžete požádat o kopii osobních údajů,
            které o vás zpracováváme.
          </li>
          <li>
            <strong>Právo na opravu:</strong> Můžete požádat o opravu nepřesných nebo
            neúplných údajů.
          </li>
          <li>
            <strong>Právo na výmaz:</strong> Můžete požádat o smazání vašich osobních
            údajů, pokud již nejsou potřebné.
          </li>
          <li>
            <strong>Právo na omezení zpracování:</strong> Můžete požádat o omezení
            zpracování vašich údajů v určitých situacích.
          </li>
          <li>
            <strong>Právo na přenositelnost:</strong> Můžete požádat o přenos vašich
            údajů jinému správci.
          </li>
          <li>
            <strong>Právo vznést námitku:</strong> Můžete vznést námitku proti zpracování
            založenému na oprávněných zájmech.
          </li>
        </ul>
      </section>

      <section className="section">
        <h2>6. Kontakt</h2>
        <p>
          Pokud máte jakékoli dotazy týkající se těchto zásad nebo chcete uplatnit
          svá práva, kontaktujte nás na:
        </p>
        <p>
          <strong>Email:</strong> sopek.filip@seznam.cz <br />
          <strong>Instagram:</strong> <a href="https://www.instagram.com/legendafilip/"> @legendafilip</a> <br />
          <strong>Lokalita:</strong> Jablonec nad Nisou, 466 01
        </p>
      </section>
           {/* Footer */}
           <footer className="footer">
        <div className="footer-content">
          <p>© 2024 Online Coaching. Všechna práva vyhrazena.</p>
          <div className="footer-links">
            <a href="/about">O mě</a>
            <a href="/contact">Kontakt</a>
            <a href="/privacy">Zásady ochrany osobních údajů</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
