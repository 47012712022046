import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate pro navigaci
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import "./App.css";

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const navigate = useNavigate(); // Inicializace navigace

  const images = [
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000138-38f1c38f1e/Recenze.png?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000140-8bdf48bdf5/Recenze2.png?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000142-5dce05dce2/Recenze3.png?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000144-883c3883c4/Recenze4.png?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000147-68bcc68bcd/450/asd.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000155-777887778a/450/petr.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000157-3deb33deb6/450/Screenshot_6.webp?ph=8f10205334",
    "https://8f10205334.cbaul-cdnwnd.com/9c52f85052a188d442cc801247b8127d/200000139-d8998d899a/450/Recenze.webp?ph=8f10205334",
  ];

  return (
    <div>
      {/* Banner Section */}
      <section className="banner">
        <div className="banner-overlay">
          <h1 className="banner-title">ONLINE COACHING</h1>
          <p className="banner-subtitle">Vypadat skvěle a být silný nedokáže každý, buď jeden z nich!</p>
          <button className="banner-button" onClick={() => navigate("/contact")}>
            Začít hned
          </button>
        </div>
      </section>

      {/* Services Section */}
      <section className="services">
        <h2 className="services-title">Průvodce na cestě k vysněné postavě</h2>
        <p className="services-subtitle">Jak vám mohu pomoci</p>
        <div className="services-grid">
          <div className="service-item">
            <img
              src="https://i.ibb.co/1sFGH9C/first.png" // Nahraďte odpovídajícím obrázkem
              alt="Service 1"
              className="service-icon"
            />
            <h3>Individuální tréninkový plán</h3>
            <p>Tréninkový plán vytvořen individuálně pro každého jedince.</p>
            <p className="service-price">1000 KČ</p>
            <button
              className="service-button"
              onClick={() => navigate("/contact")}
            >
              Začít
            </button>
          </div>
          <div className="service-item">
            <img
              src="https://i.ibb.co/RCYrPpD/secong.png" // Nahraďte odpovídajícím obrázkem
              alt="Service 2"
              className="service-icon"
            />
            <h3>Jídelníček</h3>
            <p>Jídelníček bez hladovění, dostupný i pro studenty.</p>
            <p className="service-price">800 KČ</p>
            <button
              className="service-button"
              onClick={() => navigate("/contact")}
            >
              Získat
            </button>
          </div>
          <div className="service-item">
            <img
              src="https://i.ibb.co/cQtxfzd/thirth.png" // Nahraďte odpovídajícím obrázkem
              alt="Service 3"
              className="service-icon"
            />
            <h3>Coaching</h3>
            <p>Kompletní online coaching, tréninkový plán a kontrola výsledků.</p>
            <p className="service-price">1600 KČ</p>
            <button
              className="service-button"
              onClick={() => navigate("/contact")}
            >
              Začít
            </button>
          </div>
          <div className="service-item">
            <img
              src="https://i.ibb.co/pRHD8Hn/fourth.png" // Nahraďte odpovídajícím obrázkem
              alt="Service 4"
              className="service-icon"
            />
            <h3>Osobní trénink</h3>
            <p>Kontrola techniky a kompletní vedení tréninku.</p>
            <p className="service-price">250 KČ</p>
            <button
              className="service-button"
              onClick={() => navigate("/contact")}
            >
              Získat
            </button>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="pricing">
        <h2 className="pricing-title">Ceník</h2>
        <div className="pricing-table">
          <div className="pricing-row">
            <span>Tréninkový plán</span>
            <span className="pricing-amount">1000 Kč</span>
          </div>
          <div className="pricing-row">
            <span>Jídelníček</span>
            <span className="pricing-amount">800 Kč</span>
          </div>
          <div className="pricing-row">
            <span>Kompletní coaching - měsíc</span>
            <span className="pricing-amount">1600 Kč</span>
          </div>
          <div className="pricing-row">
            <span>Kompletní coaching - 3 měsíce</span>
            <span className="pricing-amount">3200 Kč</span>
          </div>
        </div>
      </section>

      {/* Gallery Section */}
      <section className="gallery">
        <h2 className="gallery-title">Galerie</h2>
        <div className="gallery-grid">
          {images.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Gallery image ${index + 1}`}
              className="gallery-image"
              onClick={() => {
                setPhotoIndex(index);
                setIsOpen(true);
              }}
            />
          ))}
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <p>© 2024 filipsopek.cz. Všechna práva vyhrazena.</p>
          <div className="footer-links">
            <a href="/about">O mně</a>
            <a href="/contact">Kontakt</a>
            <a href="/privacy">Zásady ochrany osobních údajů</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
