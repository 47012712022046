import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div className="contact-page">
      <h1 className="contact-title">Začni hned!</h1>

      {/* Embedded Google Form */}
      <div className="form-container">
        <iframe
          src="https://docs.google.com/forms/d/1GmEZ5s_44MLO43__LiFaoYBMvUGjye4DtREuj4eecz4/viewform?embedded=true"
          width="100%"
          height="800px"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Google Form"
        >
          Načítám…
        </iframe>
      </div>

      {/* Contact Info Section */}
      <div className="contact-info">
        <h2>Kontaktní údaje</h2>
        <ul>
          <li>
            <span>📍</span> Filip Sopek <br />
            Jablonec nad Nisou, 466 01
          </li>
          <li>
            <span>📧</span> <a href="mailto:sopek.filip@seznam.cz">sopek.filip@seznam.cz</a>
          </li>
          <li>
            <span>📷</span> <a href="https://www.instagram.com/legendafilip/" target="_blank" rel="noopener noreferrer">Instagram</a>
          </li>
        </ul>
      </div>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <p>© 2024 filipsopek.cz. Všechna práva vyhrazena.</p>
          <div className="footer-links">
            <a href="/about">O mně</a>
            <a href="/contact">Kontakt</a>
            <a href="/privacy">Zásady ochrany osobních údajů</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Contact;
